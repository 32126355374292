import { useContext,  useState } from 'react';
import DataContext, { PageContext } from '../models/AppData';
import classes from './Drawer.module.css';

import icon_close from './../_assets/icons/menuClose.svg';
import icon_open from './../_assets/icons/menuOpen.svg';
import icon_contact from './../_assets/icons/telefon.svg';

import { useNavigate } from 'react-router-dom';
import { getIcon, getIconScale } from '../Helper';

function Drawer() {
  const [opened, setOpened] = useState(false);
  
  const dataCtx = useContext(DataContext);
  const data = dataCtx.data;

  const pageCtx = useContext(PageContext);
  const navigate = useNavigate();

  const clicked = (page) =>{
    //console.log(page)
    if(page.IsParent())
      navigate(page.slug+"/1/1");
    else{
      if(page.info)
        navigate(page.slug+"/0/0");
      else
        navigate(page.slug+"/1/1");
    }
    //pageCtx.setWaiting(page.IsParent());
    //pageCtx.set(page);
    setOpened(false);
  }

  if(!data?.footerPages?.children && !data?.pages)
    return(null);
  //console.log(pageCtx.page?.page?.id)
  return (
    <div className={classes.Drawer + " " + (opened?classes.opened:"")}>
      <div className={classes.icon + " " + classes.contact} onClick={()=>{navigate("/kontakt")}}>
        <span>kontakt</span>
        <div style={{'--mask':`url(${icon_contact})`}}></div>
      </div>

      <div className={classes.icon} onClick={()=>{setOpened(!opened)}}>
        <span>close</span>
        <div style={{'--mask':`url(${opened?icon_close:icon_open})`}}></div>
      </div>
      <div className={classes.container}>
        <div className={classes.main}>
        {
          data?.pages.map(mainPage=>
            <div 
            key={mainPage.id}
            className={pageCtx.page?.page?.id === mainPage.id || pageCtx.page?.page?.parent === mainPage.id?classes.active:""}
            onClick={()=>clicked(mainPage)}
            >
              
              <div className={classes.page_icon}>
                <div style={{
                  '--mask':`url(${getIcon(mainPage.slug)})`,
                  '--customScale':getIconScale(mainPage.slug)
                  }}>
                </div>
              </div>
              {mainPage.title}
            </div>
          )
        }
        </div>
        <div className={classes.footer}>
        {
          data?.footerPages[0].children.map(page=>
            <div key={page.id} onClick={()=>clicked(page)}>{page.title}</div>
          )
        }
        </div>
        <div className={classes.footer_2}>
          <span>NetAachen</span>
        </div>
        
      </div>
    </div>
  );
}

export default Drawer;
