import icon_kommunikation from './_assets/icons/display.svg';
import icon_verlaesslich from './_assets/icons/haende.svg';
import icon_verbundenheit from './_assets/icons/herz.svg';
import icon_sicherheit from './_assets/icons/schloss.svg';
import icon_infrastruktur from './_assets/icons/wolken.svg';

//import icon_haende from './_assets/icons/haende.svg';
//import icon_herz from './_assets/icons/herz.svg';

export const getIcon = (slug)=>{
  if(slug === "raum-1")
    return icon_verlaesslich;
  if(slug === "raum-2")
    return icon_verbundenheit;
  if(slug === "raum-3")
    return icon_kommunikation;
  if(slug === "raum-4")
    return icon_sicherheit;
  if(slug === "raum-5")
    return icon_infrastruktur;

  return null;
}

export const getIconScale = (slug, scope=null)=>{

  if(scope === "page"){
    if(slug === "raum-1")
      return "70%";
    if(slug === "raum-2")
      return window.portrait?"60%":"60%";
    if(slug === "raum-3")
      return window.portrait?"65%":"65%";
    if(slug === "raum-4")
      return window.portrait?"65%":"65%";
    if(slug === "raum-5")
      return window.portrait?"60%":"60%";

    return "50%";
  }
  if(slug === "raum-1")
    return "50%";
  if(slug === "raum-2")
    return window.portrait?"45%":"45%";
  if(slug === "raum-3")
    return window.portrait?"45%":"37%";
  if(slug === "raum-4")
    return window.portrait?"42%":"50%";
  if(slug === "raum-5")
    return window.portrait?"48%":"37%";

  return "50%";
}

