
import classes from './Header.module.css';
import logo from './../_assets/icons/NetAachen.svg';
import { useNavigate } from 'react-router-dom';


function Header() {
  const navigate = useNavigate();
  const clicked = () =>{
    navigate("/");
  }

  return (
    <div className={classes.Header}>
      <img className={classes.logo} src={logo} alt="Start" onClick={()=>{clicked()}}></img>
    </div>
  );
}
export default Header;