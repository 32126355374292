export var ReactEventTypes = {
	state:"STATE",//init three js 
	action:"ACTION",//init three js 	
}

export function SendEvent(eventType, data)
{
	var modelReactEvent = document.createEvent("Event");
	modelReactEvent.initEvent(eventType,true,true);
	modelReactEvent.data = data;
	document.dispatchEvent(modelReactEvent);
}

const debug = false; 
if(debug)
{
	document.addEventListener(ReactEventTypes.state,(e)=>{
		if(e.data.scope === "THREE-LOAD" && e.data.value<2)
			return;
		console.log(e.data);
	}, false);
	document.addEventListener(ReactEventTypes.action,(e)=>{console.log(e.data);}, false);	

}