import { useContext } from 'react';
import classes from './NetApp.module.css';
import Drawer from './Drawer/Drawer';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import DataContext,  { PageContext } from './models/AppData';
import PageView from './PageView/PageView';
import { useEffect } from 'react';
import { ReactEventTypes, SendEvent } from './ReactEvents';
import { useNavigate } from 'react-router-dom';

function NetApp() {
  const dataCtx = useContext(DataContext);
  const pageCtx = useContext(PageContext);
  const navigate = useNavigate();

  const threeEventHandler = (e)=>{
    if(e.data?.scope === "HOTSPOT-ONMOUSECLICK"){
        const page = dataCtx.data.Search(e.data?.value);
        navigate("/"+page.slug+"/1/0");
        dataCtx.data.clickOccured = true;
        dataCtx.data.showContent = true;
    }

    if(e.data?.scope === "HOTSPOT-ONMOUSEENTER"){
      if(!dataCtx.data.animationPlaying){
      dataCtx.data.showContent = false;
      const page = dataCtx.data.Search(e.data?.value);
      dataCtx.data.hoveringButton = e.data?.value;
      navigate("/"+page.slug+"/0/0");
      }
    }

    if(e.data?.scope === "HOTSPOT-ONMOUSLEAVE"){
       if(!dataCtx.data.animationPlaying){
      dataCtx.data.hoveringButton = null;
      navigate("/"+dataCtx.data.currentlyInRoom+"/0/0");
       }
    }

    if(e.data?.scope === "ANIMATION-FINISHED"){
      dataCtx.data.clickOccured = false;

      console.log("ANIMATION-FINISHED", e.data?.value)
      const page = dataCtx.data.Search(e.data?.value);
      //console.log(page)
      if(page){
        navigate("/"+page.slug+"/0/0");
        dataCtx.data.currentlyInRoom = page.slug;
        dataCtx.data.showContent = true;
        dataCtx.data.animationPlaying = false;
    }
    }
  }

  useEffect(()=>{
    document.addEventListener(ReactEventTypes.state, threeEventHandler);
  if(dataCtx.data.clickOccured == true && !dataCtx.data.animationPlaying){
      dataCtx.data.animationPlaying = true;
    SendEvent(ReactEventTypes.action, {scope:"ANIMATION_START", value:pageCtx?.page?.page?.slug?pageCtx?.page?.page?.slug:"0"})
    dataCtx.data.clickOccured = false;
}
   return ()=>{
      document.removeEventListener(ReactEventTypes.state, threeEventHandler);
    }
  },
  [pageCtx?.page?.page]);

  return (
    <div className={classes.NetApp}>
      <Header></Header>
      <Drawer></Drawer>
      <PageView page={pageCtx?.page} hoveredButton={dataCtx?.hoveredButton}></PageView>
      <Footer></Footer>
    </div>
  );
}

export default NetApp;
