
import { useContext } from 'react';
import DataContext, { PageContext } from '../models/AppData';
import classes from './Footer.module.css';
import { useNavigate } from "react-router-dom";
import { getIcon, getIconScale } from '../Helper';
import { ReactEventTypes, SendEvent } from '../ReactEvents';

function Footer() {
  const pageCtx = useContext(PageContext);
  const dataCtx = useContext(DataContext);
  const data = dataCtx.data;
  const navigate = useNavigate();
  const clicked = (page) =>{
    data.clickOccured = true;


    if(data.currentlyInRoom){
      if(data.currentlyInRoom == page.slug){
      navigate("/")
      data.currentlyInRoom = undefined;
      }else{
        data.currentlyInRoom = page.slug;
        navigate(page.slug+"/1/1");
        SendEvent(ReactEventTypes.action, {scope:"ANIMATION_START", value:pageCtx?.page?.page?.slug?pageCtx?.page?.page?.slug:"0"})
      }

      }else{
        data.currentlyInRoom = page.slug;
        navigate(page.slug+"/1/1");
        SendEvent(ReactEventTypes.action, {scope:"ANIMATION_START", value:pageCtx?.page?.page?.slug?pageCtx?.page?.page?.slug:"0"})
  }
  }

  const hovered = (page) =>{
    data.hoveringButton = page.slug;
        dataCtx.data.showContent = true;
    navigate(page.slug+"/0/0");
  }

  const clear = (page) =>{
    if(data.currentlyInRoom)
    navigate(data.currentlyInRoom+"/0/0");
    else
    navigate("/");
  }

  return (
    <div className={classes.Footer}>
      <div className={classes.info_icons}>
        {
          data?.footerPages[0].children.map(page=>
            {
              return <div key={page.id} onClick={()=>clicked(page)}>{page.title}</div>
            }
          )
        }
      </div>
      <div className={classes.icons}>
        {
          data?.pages.map(page=>
            <div
              key={page.id}
              onClick={()=>clicked(page)}
              onMouseEnter={() => hovered(page)}
              onMouseLeave={() => clear(page)}
              title={page.title}
              className={page.id === pageCtx?.page?.page?.id || page.id === pageCtx?.page?.page?.parent?classes.active:""}
            >
              <span>{page.title}</span>
              <div style={{
                '--mask':`url(${getIcon(page.slug)})`,
                '--customScale':getIconScale(page.slug)
                }}></div>
            </div>
          )
        }

      </div>

    </div>
  );
}

export default Footer;
